import {
  SET_ONBOARDING,
} from './actionsTypes'

const onboarding = {
  home: { users: {} },
  meusDados: { users: {} },
}

const INITIAL_STATE = {
  onboarding: JSON.parse(localStorage.getItem('@onboarding/portal-react')) || onboarding,
}

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case SET_ONBOARDING: {
    return {
      ...state,
      onboarding: action.onboarding,
    }
  }
  default:
    return state
  }
}
