
import { SET_LOADING_COVER, SET_LOADING_AVATAR, SET_PREVIEW_AVATAR_URL } from './actionsTypes'

const INITIAL_STATE = {
  loadingCover: false,
  loadingAvatar: false,
  previewURLAvatar: null,
}

const loadingCoverAvatarAndPreviewAvatarStore = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case SET_LOADING_COVER: {
    return {
      ...state,
      loadingCover: action.stateLoading,
    }
  }

  case SET_LOADING_AVATAR: {
    return {
      ...state,
      loadingAvatar: action.stateLoading,
    }
  }

  case SET_PREVIEW_AVATAR_URL: {
    return {
      ...state,
      previewURLAvatar: action.previewURLAvatar,
    }
  }

  default:
    return state
  }
}

export default loadingCoverAvatarAndPreviewAvatarStore
