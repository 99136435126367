import urls from './urls'

export const getProfileAvatarAndCoverDefautlLink = (id) => {
  const path = id
    ? `${urls.STORAGE_API_PUBLIC}/public/home/${id}.svg`
    : null

  return path
}
export default {
  getProfileAvatarAndCoverDefautlLink,
}
