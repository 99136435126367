import {
  useCallback,
  useEffect,
  useState,
} from 'react'

import DocumentModal from 'App/components/DocumentModal'

import loginService from 'App/services/loginService'

import toast from 'App/utils/toast'
import textCommons from 'App/utils/textCommons'
import useFetchPersonalData from 'App/components/Menu/hooks/useFetchPersonalData'
import useStoreUsers from 'App/store/userStore/useStoreUsers'
import { logout } from 'App/utils/auth'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import styles from './TermsOfUse.module.scss'
import Button from '../Button'

const steps = [
  'termsOfUse',
  'privacy',
  'accept',
]

const TermsOfUse = () => {
  const { setLoggedUserByData } = useStoreUsers()
  const { loggedUser } = useSelector((state) => state.users)

  const {
    getPersonalData,
  } = useFetchPersonalData({ setLoggedUserByData })

  const [
    isFetchingTerms,
    setIsFetchingTerms,
  ] = useState(false)

  const [
    isModalVisible,
    setIsVisible,
  ] = useState(false)
  const [
    documentText,
    setDocument,
  ] = useState('')

  const [
    currentStep,
    setCurrentStep,
  ] = useState(steps[0])

  const [
    isLoading,
    setIsLoading,
  ] = useState(false)

  const [
    documentVersion,
    setDocumentVersion,
  ] = useState(1)

  const [
    permissionDivActive,
    setPermissionDivActive,
  ] = useState(false)

  const open = () => setIsVisible(true)
  const close = () => setIsVisible(false)

  const loadTermsOfUseDocumentCallback = useCallback(
    async () => {
      setIsFetchingTerms(true)
      try {
        const { data: termsOfUse } = await loginService.getTermsOfUseVersion()

        if (loggedUser?.user?.acceptedTermsOfServiceVersion !== termsOfUse.version) {
          const { version, document } = await loginService.getTermsOfUse()

          setDocumentVersion(version)
          setDocument(document)

          open()
        }
      } catch (error) {
        toast.error('Ocorreu um erro ao tentar abrir seus termos de uso. Verifique sua conexão com a internet e tente novamente.')
      } finally {
        setIsFetchingTerms(false)
      }
    },
    [loggedUser],
  )

  useEffect(() => {
    loadTermsOfUseDocumentCallback()
  }, [loadTermsOfUseDocumentCallback])

  const loadPrivacyNoticeCallback = useCallback(
    async () => {
      setIsLoading(true)

      try {
        const { data: privacyDocument } = await loginService.getPrivaceText(documentVersion)

        setDocument(privacyDocument)
      } finally {
        setIsLoading(false)
      }
    },
    [
      documentVersion,
      setIsLoading,
    ],
  )

  const acceptTermsCallback = useCallback(async () => {
    setIsLoading(true)

    try {
      await loginService.acceptTermsOfService({
        version: documentVersion,
        acceptedDataSharePolicy: true,
      })

      getPersonalData()

      toast.success(textCommons.updated)
      close()
    } catch (error) {
      toast.error('Ocorreu um erro ao tentar atualizar seus termos de uso. Verifique sua conexão com a internet e tente novamente.')
    } finally {
      setIsLoading(false)
    }
  }, [
    documentVersion,
    getPersonalData,
  ])

  const getCurrentStep = useCallback(
    async () => {
      switch (currentStep) {
      case 'termsOfUse':
        await loadTermsOfUseDocumentCallback()
        break
      case 'privacy':
        await loadPrivacyNoticeCallback()
        break
      case 'accept':
        await acceptTermsCallback()
        break
      default:
        window.location.reload(true)
      }
    },
    [
      currentStep,
      acceptTermsCallback,
      loadPrivacyNoticeCallback,
      loadTermsOfUseDocumentCallback,
    ],
  )

  useEffect(() => {
    getCurrentStep()
  }, [getCurrentStep])

  const getPrimaryButtonText = () => {
    if (currentStep === steps[0]) {
      return 'Aceitar e continuar'
    }

    if (currentStep === steps[1]) {
      return 'Aceito todas as permissões'
    }

    return 'Aceito'
  }

  const onClickPrimaryButton = () => {
    const currentIndex = steps.indexOf(currentStep)
    const nextStep = steps[currentIndex + 1]

    setCurrentStep(nextStep)
  }

  const onClickBackButton = () => {
    if (currentStep === steps[0]) {
      logout()
    } else {
      const currentIndex = steps.indexOf(currentStep)

      setCurrentStep(steps[currentIndex - 1])
    }
  }

  const GetFooter = () => (
    <div className={styles.footer}>
      <Button
        variation="tertiary"
        onClick={onClickBackButton}
      >
        Voltar
      </Button>

      <div>
        {currentStep === steps[1] && (
          <Button
            className={styles.managePermissionsBtn}
            variation="tertiary"
            onClick={() => setPermissionDivActive(!permissionDivActive)}
          >
            Gerir permissões
          </Button>
        )}

        <Button
          isLoading={isLoading}
          variation="primary"
          onClick={onClickPrimaryButton}
        >
          {getPrimaryButtonText()}
        </Button>
      </div>
    </div>
  )

  return (
    <section className={styles.termsContainer}>
      <DocumentModal
        documentText={documentText}
        footer={(
          <div>
            <div className={clsx(
              styles.slideTop,
              permissionDivActive && styles.slideTopActive,
            )}
            >
              Permito compartilhar meus dados de navegação para a melhoria de
              usabilidade e desempenho do Portal
            </div>

            <GetFooter />
          </div>
        )}
        isLoading={isFetchingTerms}
        isOpen={isModalVisible}
        isBackdropClickDisabled
        isCloseButtonDisabled
      />

    </section>
  )
}

export default TermsOfUse
