const production = {
  AUTH_API: 'https://auth-server.portalsaseducacao.com.br',
  AUTH_API_V2: 'https://auth-server.portalsaseducacao.com.br/v2',
  BASE_API: '/',
  PORTAL_URL: 'https://portalsaseducacao.com.br',
  PORTAL_URL_LOGO_MENU: 'https://home.portalsaseducacao.com.br',
  PUBLIC_FILES_URL: 'https://public.portalsaseducacao.com.br',
  QUESTION_PLATFORM_URL: 'https://plataformadequestoes.portalsaseducacao.com.br',
  STORAGE_API_PUBLIC: 'https://storage.portalsaseducacao.com.br',
  STATIC_URL: 'https://static.portalsaseducacao.com.br',
  //  sasdigital.com.br
  PORTAL_API: 'https://portal-api.sasdigital.com.br',
  PORTAL_API_URL: 'https://portal-api.sasdigital.com.br',
  SCHOOL_API: 'https://schoolapi.sasdigital.com.br/api/v1',
  STORAGE_API: 'https://storage-service.sasdigital.com.br',
}

const staging = {
  AUTH_API: 'https://auth-server.staging.portalsaseducacao.com.br',
  AUTH_API_V2: 'https://auth-server.staging.portalsaseducacao.com.br/v2',
  BASE_API: '/',
  PORTAL_URL: 'https://portal.staging.portalsaseducacao.com.br',
  PORTAL_URL_LOGO_MENU: 'https://home.staging.portalsaseducacao.com.br',
  PUBLIC_FILES_URL: 'https://public.staging.portalsaseducacao.com.br',
  QUESTION_PLATFORM_URL: 'https://plataformadequestoes.staging.portalsaseducacao.com.br',
  STORAGE_API_PUBLIC: 'https://storage.portalsaseducacao.com.br',
  STATIC_URL: 'static://public.staging.portalsaseducacao.com.br',
  // sasdigital.com.br
  PORTAL_API: 'https://portal-api.staging.sasdigital.com.br',
  PORTAL_API_URL: 'https://portal-api.staging.sasdigital.com.br',
  SCHOOL_API: 'https://schoolapi.staging.sasdigital.com.br/api/v1',
  STORAGE_API: 'https://storage-service.staging.sasdigital.com.br',
}

const development = {
  ...staging,
  PORTAL_URL: 'http://localhost:3000',
  PORTAL_URL_LOGO_MENU: 'http://localhost:3000',
}

const urlsByMode = {
  production,
  staging,
  development,
}

const urls = urlsByMode[process.env.REACT_APP_MODE]

export default urls
