import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  ChevronDown as ArrowIcon,
  Check as CheckIcon,
} from 'react-feather'

import { sizes, status as statusList, MEDIUM } from 'App/utils/configurations'
import Spinner from 'App/components/Spinner'

import styles from './SelectButton.module.scss'

const SelectButton = forwardRef(function SelectButton({
  children,
  counter,
  isActive,
  isChecked,
  isDisabled,
  isFluid,
  isLoading,
  isButtonText,
  prepend,
  size,
  status,
  ...remainingProps
}, ref) {
  const SelectIcon = () => {
    if (!isLoading) {
      if (isChecked) {
        return (
          <CheckIcon
            className={clsx(styles.append, styles.check)}
            size={16}
            strokeWidth={2}
          />
        )
      }

      return (
        <ArrowIcon
          className={clsx(styles.append, styles.arrow)}
          size={16}
          strokeWidth={2}
        />
      )
    }

    return (
      <Spinner
        className={clsx(styles.append, styles.spinner)}
        size="small"
      />
    )
  }

  return (
    <button
      ref={ref}
      aria-busy={isLoading}
      aria-haspopup="true"
      className={clsx(
        styles.button,
        styles[`--${size}`],
        [styles[status]],
        {
          [styles.isActive]: isActive,
          [styles.isChecked]: isChecked,
          [styles.isFluid]: isFluid,
          [styles.loading]: isLoading,
          [styles.isButtonText]: isButtonText,
        },
      )}
      disabled={isDisabled || isLoading}
      type="button"
      {...remainingProps}
    >
      {prepend && (
        <span className={styles.prepend}>{prepend}</span>
      )}

      <span className={styles.content}>
        <span className={styles.text}>{children}</span>

        {counter > 1 && (
          <span className={styles.counter}>{`+${counter - 1}`}</span>
        )}
      </span>

      {!isButtonText && <SelectIcon />}
    </button>
  )
})

SelectButton.propTypes = {
  children: PropTypes.node,
  counter: PropTypes.number,
  isActive: PropTypes.bool,
  isChecked: PropTypes.bool,
  isButtonText: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isFluid: PropTypes.bool,
  isLoading: PropTypes.bool,
  prepend: PropTypes.node,
  size: PropTypes.oneOf(sizes),
  status: PropTypes.oneOf([
    'default',
    ...statusList,
  ]),
}

SelectButton.defaultProps = {
  children: null,
  counter: 0,
  isActive: false,
  isButtonText: false,
  isChecked: false,
  isDisabled: false,
  isFluid: false,
  isLoading: false,
  prepend: null,
  size: MEDIUM,
  status: '',
}

export default SelectButton
