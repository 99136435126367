import { lazy } from 'react'

export default [
  {
    path: '/meus-dados',
    component: lazy(() => import('./PersonalData')),
    name: 'personalData',
    exact: true,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/alterar-email/:token',
    component: lazy(() => import('./ChangeMail')),
    name: 'ChangeMail',
    exact: true,
    meta: {
      allowAnonymous: true,
      withoutMenu: true,
    },
  },
]
