import { toast, ToastContainer } from 'react-toastify'
import {
  Info as InfoIcon,
  Check as SuccessIcon,
  AlertCircle as WarningIcon,
  AlertTriangle as ErrorIcon,
} from 'react-feather'
import 'react-toastify/dist/ReactToastify.css'

import Icon from './Icon'
import CloseButton from './CloseButton'

import styles from './toast.module.scss'

import './toast.scss'

export const toastContainer = (
  <ToastContainer
    autoClose={5000}
    className={styles.container}
    closeButton={CloseButton}
    position={toast.POSITION.BOTTOM_LEFT}
    closeOnClick
    pauseOnHover
  />
)

export default {
  info: (content, options = {}) => {
    toast.info(
      <>
        <Icon as={InfoIcon} />

        {content}
      </>,
      options,
    )
  },
  success: (content, options = {}) => {
    toast.success(
      <>
        <Icon as={SuccessIcon} />

        {content}
      </>,
      options,
    )
  },
  warning: (content, options = {}) => {
    toast.warning(
      <>
        <Icon as={WarningIcon} />

        {content}
      </>,
      options,
    )
  },
  error: (content, options = {}) => {
    toast.error(
      <>
        <Icon as={ErrorIcon} />

        {content}
      </>,
      options,
    )
  },
}
