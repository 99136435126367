import { forwardRef } from 'react'
import PropTypes from 'prop-types'

import FormTextError from 'App/components/FormTextError'
import FormGroupContext from './FormGroupContext'

import style from './FormGroup.module.scss'

const canPrintMessage = (state) => typeof state === 'string'

const FormGroup = forwardRef(function FormGroup({
  children,
  error,
  success,
  warn,
}, ref) {
  const getFormGroupStatus = () => {
    if (success) {
      return { status: 'success' }
    }

    if (warn) {
      return { status: 'warn' }
    }

    if (error) {
      return { status: 'error' }
    }

    return { status: 'default' }
  }
  const contextValue = getFormGroupStatus()

  return (
    <FormGroupContext value={contextValue}>
      <div
        ref={ref}
        className={style.formGroup}
      >
        {children}

        {canPrintMessage(success)}

        {canPrintMessage(warn)}

        {canPrintMessage(error) && <FormTextError>{error}</FormTextError>}
      </div>
    </FormGroupContext>
  )
})

FormGroup.propTypes = {
  children: PropTypes.node,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  success: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  warn: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
}

FormGroup.defaultProps = {
  children: null,
  error: null,
  success: null,
  warn: null,
}

export default FormGroup
