import { useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  Repeat as RepeatIcon,
  User as UserIcon,
  LogIn as LogInIcon,
} from 'react-feather'
import { noop } from 'lodash-es'

import { logout } from 'App/utils/auth'
import urls from 'App/utils/urls'

import useMenuContext from '../../hooks/useMenuContext'

import ProfileMenuLink from '../ProfileMenuLink'
import ProfileModal from '../ProfileModal'
import RatePortalButton from '../../components/RatePortalButton/RatePortalButton'

const ProfileMenu = ({
  isModalOpen,
  onCloseModal,
  onOpenModal,
}) => {
  const { currentProfile } = useMenuContext()

  const isChangeProfileVisible = useMemo(() => {
    if (!currentProfile?.profileId) {
      return false
    }

    const { profiles } = currentProfile

    if (
      profiles.length > 1
      || (profiles.length === 1 && profiles[0].schools.length > 1)
    ) {
      return true
    }

    return false
  }, [currentProfile])

  return (
    <ul role="menu">
      <li role="none">
        {isChangeProfileVisible
          ? (
            <>
              <ProfileMenuLink
                icon={RepeatIcon}
                role="menuitem"
                isPrimary
                onClick={onOpenModal}
              >
                Trocar / Adicionar acesso
              </ProfileMenuLink>

              <ProfileModal
                isOpen={isModalOpen}
                onClose={onCloseModal}
              />
            </>
          ) : (
            <ProfileMenuLink
              href={`${urls.PORTAL_URL}/ativar-acesso`}
              icon={RepeatIcon}
              role="menuitem"
              isPrimary
            >
              Adicionar Acesso
            </ProfileMenuLink>
          )}
      </li>

      <li role="none">
        <ProfileMenuLink
          href={process.env.NODE_ENV === 'production' ? `${urls.PORTAL_URL}/meus-dados` : '/meus-dados'}
          icon={UserIcon}
          role="menuitem"
        >
          Meus Dados
        </ProfileMenuLink>
      </li>

      <li role="none">
        <RatePortalButton surveyId="qLTNvf1Q" />
      </li>

      <li role="none">
        <ProfileMenuLink
          icon={LogInIcon}
          role="menuitem"
          onClick={logout}
        >
          Sair
        </ProfileMenuLink>
      </li>
    </ul>
  )
}

ProfileMenu.propTypes = {
  isModalOpen: PropTypes.bool,
  onCloseModal: PropTypes.func,
  onOpenModal: PropTypes.func,
}

ProfileMenu.defaultProps = {
  isModalOpen: false,
  onCloseModal: noop,
  onOpenModal: noop,
}

export default ProfileMenu
