const profilesTypes = {
  admin: 1,
  student: 2,
  teacher: 3,
  consultant: 5,
  guardian: 6,
  school: 7,
  helpdesk: 10,
  financialManager: 11,
  contentAdmin: 12,
  content: 14,
  orderStore: 16,
  coordinator: 17,
  schoolAdministration: 18,
  opcom: 19,
  educational: 20,
  provider: 22,
  manager: 23,
  sasProduction: 24,
  integration: 100,
}

export default Object.freeze(profilesTypes)
