export default Object.freeze({
  actions: {
    back: 'Voltar',
    search: 'Pesquisar',
    clear: 'Limpar',
    filter: 'Filtrar',
    cancel: 'Cancelar',
    confirm: 'Confirmar',
  },
  profiles: {
    admin: 'Administrador',
    student: 'Aluno',
    teacher: 'Professor',
    consultant: 'Consultor',
    guardian: 'Tutor',
    school: 'Escola',
    helpdesk: 'Suporte',
    financialManager: 'Gerente Financeiro',
    contentAdmin: 'Conteúdo',
    orderStore: 'Logistica',
    coordinator: 'Coordenador',
    schoolAdministration: 'Secretaria',
    provider: 'Fornecedor',
    manager: 'Gerenciamento',
    production: 'Produção',
  },
  errors: {
    required: 'Campo obrigatório',
    generic: 'Aconteceu um erro inesperado. Se persistir, entre em contato com o suporte',
    emptyForNow: 'Não há nada aqui ainda',
    updateData: 'Ocorreu um erro ao tentar atualizar seus dados. Verifique sua conexão com a internet e tente novamente.',
  },
  api: {
    createItemSuccessMessage: 'Item foi criado com sucesso',
    deleteItemSuccessMessage: 'Item foi removido com sucesso',
    updateItemSuccessMessage: 'Item foi atualizado com sucesso',
  },
  list: {
    emptyMessageWithFilter: 'Não encontramos nenhum item para os termos pesquisados',
    noResultsFound: 'Nenhum resultado encontrado',
    noResultsFoundForTerm: 'Nenhum resultado encontrado para',
  },
  filters: {
    filterBy: 'Filtrar por',
  },
  loading: 'Carregando',
  updated: 'Pronto! Atualizamos seus dados pessoais.',
})
