import { SET_ONBOARDING, CLEAR_STORE } from './actionsTypes'

export function setOnboarding(onboarding) {
  localStorage.setItem('@onboarding/portal-react', JSON.stringify(onboarding))

  return {
    type: SET_ONBOARDING,
    onboarding,
  }
}

export function clearStore(state) {
  return {
    type: CLEAR_STORE,
    state,
  }
}
