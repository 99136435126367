const httpCodes = {
  badRequest: 400,
  unauthorized: 401,
  notFound: 404,
  notAcceptable: 406,
  conflict: 409,
  unprocessableEntity: 422,
  internalServerError: 500,
  serviceUnavailable: 503,
  successRequest: 200,
  successRequestAndNoContent: 204,
}

export default Object.freeze(httpCodes)
