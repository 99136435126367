import { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import Menu from 'App/components/Menu'
import profilesTypes from 'App/enums/profilesTypes'
import useStoreUsers from 'App/store/userStore/useStoreUsers'
import { objectIsEmpty } from 'App/utils/object'
import urls from 'App/utils/urls'

const WithMenuLayout = ({
  children,
}) => {
  const { loggedUser } = useSelector((state) => state.users)
  const { setLoggedUserByData } = useStoreUsers()

  const shouldRedirectUserToQuestioningPlatform = useCallback(() => {
    const { profileId } = loggedUser
    const questionsPlatformProfileIds = new Set([
      profilesTypes.manager,
      profilesTypes.sasProduction,
      profilesTypes.provider,
    ])
    if (questionsPlatformProfileIds.has(profileId)) {
      return window.location.assign(`${urls.QUESTION_PLATFORM_URL}`)
    }

    return null
  }, [loggedUser])

  useEffect(() => {
    shouldRedirectUserToQuestioningPlatform()
  }, [shouldRedirectUserToQuestioningPlatform])

  return (
    <>
      <Menu onGetPersonalData={setLoggedUserByData} />

      {!objectIsEmpty(loggedUser) && (
        <>
          {children}
        </>
      )}
    </>
  )
}

WithMenuLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default WithMenuLayout
