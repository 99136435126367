import { useEffect } from 'react'
import PropTypes from 'prop-types'

import { isFocusable, focusableQuerySelectors } from 'App/utils/dom'

import styles from './TrapFocus.module.scss'

const TrapFocus = ({
  children,
  containerRef,
  elementToFocusOnEnableRef,
  isDisabled,
  isFocusOnFirstItemOnActiveEnabled,
}) => {
  const TrapFocusDiv = !isDisabled && (
    <div
      className={styles.trapFocusDiv}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex="0"
    />
  )

  useEffect(() => {
    const container = containerRef ? containerRef.current || containerRef : null
    const firstFocusable = container?.querySelector(focusableQuerySelectors)

    const handleTrapFocus = (event) => {
      if (
        !isDisabled
        && container
        && isFocusable(event.target)
        && !container?.contains(event.target)
      ) {
        if (firstFocusable) {
          firstFocusable.focus()
        } else if (elementToFocusOnEnableRef?.current) {
          elementToFocusOnEnableRef.current.focus()
        }
      }
    }

    if (!isDisabled && container) {
      const handleAddFocusListener = () => {
        if (elementToFocusOnEnableRef?.current) {
          elementToFocusOnEnableRef.current.focus()
        } else if (firstFocusable && isFocusOnFirstItemOnActiveEnabled) {
          firstFocusable.focus()
        }

        document.addEventListener('focus', handleTrapFocus, true)
      }

      setTimeout(handleAddFocusListener)
    }

    return () => document.removeEventListener('focus', handleTrapFocus, true)
  }, [
    containerRef,
    elementToFocusOnEnableRef,
    isDisabled,
    isFocusOnFirstItemOnActiveEnabled,
  ])

  return (
    <>
      {TrapFocusDiv}

      {children}

      {TrapFocusDiv}
    </>
  )
}

TrapFocus.propTypes = {
  children: PropTypes.node,
  containerRef: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.object,
  ]),
  elementToFocusOnEnableRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  isDisabled: PropTypes.bool,
  isFocusOnFirstItemOnActiveEnabled: PropTypes.bool,
}

TrapFocus.defaultProps = {
  children: null,
  containerRef: null,
  elementToFocusOnEnableRef: null,
  isDisabled: false,
  isFocusOnFirstItemOnActiveEnabled: false,
}

export default TrapFocus
