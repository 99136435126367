import { useState } from 'react'
import PropTypes from 'prop-types'

import { noop } from 'App/utils'
import { isNotEmpty } from 'App/utils/array'
import textCommons from 'App/utils/textCommons'

import Input from 'App/components/Input'

import styles from './SelectSearch.module.scss'

const SelectSearch = ({
  isGrouped,
  options,
  placeholder,
  onChange,
}) => {
  const [
    searchTerm,
    setSearchTerm,
  ] = useState('')

  const handleSearch = ({ target: { value } }) => {
    let filteredOptions = options

    setSearchTerm(value)

    if (value !== '') {
      const term = value.toLowerCase()
      const hasSearchedTerm = ({ $text }) => $text.toLowerCase().includes(term)

      if (isGrouped) {
        filteredOptions = options
          .map((option) => {
            const items = option.items || []

            return {
              ...option,
              items: items.filter(hasSearchedTerm),
            }
          })
          .filter((option) => isNotEmpty(option.items))
      } else {
        filteredOptions = options.filter(hasSearchedTerm)
      }
    }

    onChange(filteredOptions)
  }

  return (
    <div className={styles.search}>
      <form>
        <Input
          aria-label={placeholder}
          className={styles.input}
          placeholder={placeholder}
          value={searchTerm}
          onChange={handleSearch}
        />
      </form>
    </div>
  )
}

SelectSearch.propTypes = {
  isGrouped: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
}

SelectSearch.defaultProps = {
  isGrouped: false,
  onChange: noop,
  options: [],
  placeholder: textCommons.actions.search,
}

export default SelectSearch
