import PropTypes from 'prop-types'
import clsx from 'clsx'

import styles from './InputExample.module.scss'

const InputExample = ({ example: exampleProp }) => {
  const example = exampleProp && (
    <div
      className={clsx(
        styles.example,
        'sas-input-example',
      )}
    >
      {exampleProp}
    </div>
  )

  return example
}

InputExample.propTypes = {
  example: PropTypes.string,
}

InputExample.defaultProps = {
  example: null,
}

export default InputExample
