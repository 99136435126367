import TourProviderContainer from 'App/components/Onboarding/Tour/TourProviderContainer'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import store from './App/store/store'

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <TourProviderContainer>
          <App />
        </TourProviderContainer>
      </BrowserRouter>
    </Provider>
  </StrictMode>,
  document.getElementById('root'),
)
