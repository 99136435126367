import {
  useCallback,
  useState,
} from 'react'

import service from '../service'

const useFetchSpecificMenu = () => {
  const [
    specificMenu,
    setSpecificMenu,
  ] = useState([])
  const [
    isLoadingSpecificMenus,
    setLoadingSpecificMenus,
  ] = useState(true)
  const [
    hasLoadingSpecificMenusError,
    setLoadingSpecificMenusError,
  ] = useState(false)

  const getSpecificMenu = useCallback(async () => {
    setSpecificMenu([])
    setLoadingSpecificMenus(true)
    setLoadingSpecificMenusError(false)

    try {
      const { data } = await service.getSpecificMenu()

      setSpecificMenu(data.subItems)
    } catch {
      setLoadingSpecificMenusError(true)
    } finally {
      setLoadingSpecificMenus(false)
    }
  }, [])

  return {
    specificMenu,
    isLoadingSpecificMenus,
    hasLoadingSpecificMenusError,
    getSpecificMenu,
  }
}

export default useFetchSpecificMenu
