import {
  createContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import { noop } from 'lodash-es'

import useFetchMenus from './hooks/useFetchMenus'
import useFetchSpecificMenu from './hooks/useFetchSpecificMenu'
import useFetchPersonalData from './hooks/useFetchPersonalData'
import useFetchProfiles from './hooks/useFetchProfiles'

const MenuContext = createContext()

const MenuProvider = ({
  children,
  onGetPersonalData,
}) => {
  const isDesktopMediaQuery = useMediaQuery({ minWidth: 1200 })

  const {
    menus,
    isLoadingMenus,
    hasLoadingMenusError,
    getMenus,
  } = useFetchMenus()

  const {
    specificMenu,
    isLoadingSpecificMenus,
    hasLoadingSpecificMenusError,
    getSpecificMenu,
  } = useFetchSpecificMenu()

  const {
    personalData,
    isLoadingPersonalData,
    hasLoadingPersonalDataError,
    getPersonalData,
  } = useFetchPersonalData({ onGetPersonalData })

  const {
    currentProfile,
    isLoadingCurrentProfile,
    hasLoadingCurrentProfileError,
    getProfiles,
  } = useFetchProfiles()

  const [
    actionsPortalNode,
    setActionsPortalNode,
  ] = useState(null)

  useEffect(() => {
    getMenus()
    getSpecificMenu()
    getPersonalData()
    getProfiles()
  }, [
    getMenus,
    getSpecificMenu,
    getPersonalData,
    getProfiles,
  ])

  const value = useMemo(() => ({
    menus,
    isLoadingMenus,
    hasLoadingMenusError,
    getMenus,
    specificMenu,
    isLoadingSpecificMenus,
    hasLoadingSpecificMenusError,
    getSpecificMenu,
    personalData,
    isLoadingPersonalData,
    hasLoadingPersonalDataError,
    getPersonalData,
    currentProfile,
    isLoadingCurrentProfile,
    hasLoadingCurrentProfileError,
    getProfiles,
    actionsPortalNode,
    setActionsPortalNode,
    isDesktopMediaQuery,
  }), [
    menus,
    isLoadingMenus,
    hasLoadingMenusError,
    getMenus,
    specificMenu,
    isLoadingSpecificMenus,
    hasLoadingSpecificMenusError,
    getSpecificMenu,
    personalData,
    isLoadingPersonalData,
    hasLoadingPersonalDataError,
    getPersonalData,
    currentProfile,
    isLoadingCurrentProfile,
    hasLoadingCurrentProfileError,
    getProfiles,
    actionsPortalNode,
    setActionsPortalNode,
    isDesktopMediaQuery,
  ])

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>
}

MenuProvider.propTypes = {
  children: PropTypes.node,
  onGetPersonalData: PropTypes.func,
}

MenuProvider.defaultProps = {
  children: null,
  onGetPersonalData: noop,
}

export { MenuContext, MenuProvider }
