import {
  useCallback,
  useState,
} from 'react'

import service from '../service'

const profileSkeleton = { name: '' }

const useFetchProfiles = () => {
  const [
    currentProfile,
    setCurrentProfile,
  ] = useState(profileSkeleton)
  const [
    isLoadingCurrentProfile,
    setLoadingCurrentProfile,
  ] = useState(true)
  const [
    hasLoadingCurrentProfileError,
    setLoadingCurrentProfileError,
  ] = useState(false)

  const getProfiles = useCallback(async () => {
    setCurrentProfile(profileSkeleton)
    setLoadingCurrentProfile(true)
    setLoadingCurrentProfileError(false)

    try {
      const { data } = await service.getProfiles()

      setCurrentProfile(data)
    } catch {
      setLoadingCurrentProfileError(true)
    } finally {
      setLoadingCurrentProfile(false)
    }
  }, [])

  return {
    currentProfile,
    isLoadingCurrentProfile,
    hasLoadingCurrentProfileError,
    getProfiles,
  }
}

export default useFetchProfiles
