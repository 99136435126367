import PropTypes from 'prop-types'
import { noop } from 'lodash-es'

import useGTM, { CUSTOM_EVENT_ANALYTICS, menuGTM } from 'App/hooks/useGTM'

import getIntegrationLink from '../utils/getIntegrationLink'

import styles from './ItemLink.module.scss'

const ItemLink = ({
  isActive,
  item,
  onActive,
  ...remainingProps
}) => {
  const { trackActionWithNotLabel } = useGTM()

  const isGroup = item.type === 'group'

  const handleActive = () => {
    onActive(item?.id)
  }

  const Content = () => (
    <>
      <span>
        {item.name}
      </span>

      {isGroup && <span className={styles.icon}>›</span>}
    </>
  )

  if (isGroup) {
    return (
      <button
        aria-expanded={isActive}
        aria-haspopup="true"
        className={styles.link}
        role="menuitem"
        type="button"
        onClick={handleActive}
      >
        <Content />
      </button>
    )
  }

  return (
    <a
      className={styles.link}
      href={getIntegrationLink(item)}
      rel="noreferrer"
      role="menuitem"
      target={item.displayMode === 'newTab' ? '_blank' : '_self'}
      {...remainingProps}
      onClick={() => trackActionWithNotLabel({
        event: CUSTOM_EVENT_ANALYTICS,
        category: menuGTM.category,
        action: item.name,
      })}
    >
      <Content />
    </a>
  )
}

ItemLink.propTypes = {
  isActive: PropTypes.bool,
  item: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    displayMode: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  onActive: PropTypes.func,
}

ItemLink.defaultProps = {
  isActive: false,
  item: {},
  onActive: noop,
}

export default ItemLink
