import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { v4 as uuid } from 'uuid'

import { noop } from 'App/utils'
import { MEDIUM, sizes } from 'App/utils/configurations'

import SelectableInputLabel from 'App/components/SelectableInputLabel'

import styles from './Checkbox.module.scss'

const Checkbox = forwardRef(function Checkbox({
  isChecked,
  className,
  isDisabled,
  id,
  isIndeterminate,
  inputRef,
  label,
  onChange,
  size,
  ...remainingProps
}, ref) {
  const inputId = id || `sas-checkbox-${uuid()}`

  const classes = {
    hasFullWidth: '--full-width',
    isIndeterminate: '--indeterminate',
    isDisabled: '--disabled',
    size: `--${size}`,
  }

  return (
    <div
      ref={ref}
      className={clsx(
        styles.checkbox,
        styles[classes.size],
        classes.size,
        isDisabled && [
          styles[classes.isDisabled],
          classes.isDisabled,
        ],
        isIndeterminate && [
          styles[classes.isIndeterminate],
          classes.isIndeterminate,
        ],
        'sas-checkbox',
        className,
      )}
    >
      <input
        ref={inputRef}
        checked={isChecked}
        className={clsx(styles.checkboxField, 'sas-checkbox-field')}
        disabled={isDisabled}
        id={inputId}
        type="checkbox"
        onChange={onChange}
        {...remainingProps}
      />

      {label && (
        <SelectableInputLabel
          className="sas-checkbox-label"
          htmlFor={inputId}
          isDisabled={isDisabled}
        >
          {label}
        </SelectableInputLabel>
      )}
    </div>
  )
})

Checkbox.propTypes = {
  isChecked: PropTypes.bool,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isIndeterminate: PropTypes.bool,
  id: PropTypes.string,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  label: PropTypes.node,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(sizes),
}

Checkbox.defaultProps = {
  className: null,
  id: null,
  inputRef: null,
  isChecked: null,
  isDisabled: false,
  isIndeterminate: false,
  label: null,
  onChange: noop,
  size: MEDIUM,
}

export default Checkbox
