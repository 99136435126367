import { combineReducers } from 'redux'

import users from 'App/store/userStore/reducer'
import loadingCoverAvatarAndPreviewAvatarStore from './loadingCoverAvatarAndPreviewAvatarStore/reducer'
import userMenu from './userMenu/reducer'
import quickAccess from './quickAccess/reducer'
import onboarding from './onboarding/reducer'
import zendeskLink from './zendeskLink/reducer'

export default combineReducers({
  users,
  loadingCoverAvatarAndPreviewAvatarStore,
  userMenu,
  quickAccess,
  onboarding,
  zendeskLink,
})
