import axios from 'axios'
import api from 'App/api'

import profilesTypes from 'App/enums/profilesTypes'
import { isTrustedDomain } from 'App/utils/auth'
import urls from 'App/utils/urls'

const portalApiUrl = urls.PORTAL_API
const authApiUrl = urls.AUTH_API
const DEFAULT_SCHOOL_ID = 22

const getTermsOfUseVersion = () => axios.get(`${urls.PUBLIC_FILES_URL}/termos/terms-of-service-version.json`)
const getProfiles = () => api.get(`${authApiUrl}/v2/users/me/profiles`)
const getSchoolContracts = (schoolCode) => api.get(`${urls.SCHOOL_API}/schools/code/${schoolCode}/contracts`)

export default {
  login: ({ login, password }) => axios.post(`${authApiUrl}/v2/auth`, {
    login: login.toLowerCase(),
    password,
  }),
  putLogin: (login) => api.put('v2/personal-data/login', login),
  getLoginSession: ({
    schoolId = DEFAULT_SCHOOL_ID,
    isHelpDeskLoginAsSchool = false,
    ...payloadRest
  }) => api.post('v2/auth/tinySession', {
    schoolId,
    isHelpDeskLoginAsSchool,
    ...payloadRest,
  }),
  validateLogin: ({ login, password }) => api.post(`${authApiUrl}/v2/account/credentials`, {
    login: login.toLowerCase(),
    password,
  }),
  deleteNewAvatar: () => api.delete(`${authApiUrl}/v2/personal-data/picture`),
  putPassword: (password) => api.put(`${authApiUrl}/v2/personal-data/password`, password),
  putPersonalData: (profile) => api.put(`${authApiUrl}/v2/personal-data`, profile, {
    headers: { 'Content-Type': 'multipart/form-data' },
  }),
  getStudent: () => api.get('v2/personal-data/student'),
  getLoggedUserHomePage: () => api.get(`${urls.PORTAL_API_URL}/v2/homePages/current`),
  getTermsOfUseVersion,
  getTermsOfUse: async (url) => {
    let termsOfUse = {}

    if (url) {
      termsOfUse.url = url
    } else {
      const { data } = await getTermsOfUseVersion()

      termsOfUse = data
    }

    if (!isTrustedDomain(termsOfUse.url)) {
      return Promise.reject(new Error(`Try to get from untrusted url ${termsOfUse.url}`))
    }

    const { data: termsOfUseDocument } = await api.get(termsOfUse.url, {
      responseType: 'text',
    })

    return Promise.resolve({
      url: termsOfUse.url,
      version: termsOfUse.version,
      document: termsOfUseDocument,
    })
  },
  getPrivaceText: (version, profileId) => {
    let filePath = `/termos/v${version}/aviso-privacidade-escola.html`

    if (profileId === profilesTypes.student || profileId === profilesTypes.guardian) {
      filePath = `/termos/v${version}/aviso-privacidade.html`
    }

    return api.get(`${urls.PUBLIC_FILES_URL}${filePath}`, { responseType: 'text' })
  },
  acceptTermsOfService: ({ version, acceptedDataSharePolicy }) => api.post(`${urls.SCHOOL_API}/users/acceptTermsOfService`, {
    version,
    acceptedDataSharePolicy,
  }),
  changeProfile: ({ profileId, schoolId }) => api.post('v2/auth/changeProfile', {
    profileId,
    schoolId,
  }),
  toggleNPS: () => api.post(`${authApiUrl}/v2/personal-data/toggle-nps`),
  getCurrentUser: () => api.get(`${authApiUrl}/v1/users/me`),
  getMenus: () => api.get(`${portalApiUrl}/usermenu`),
  getEmailStatus: () => api.get(`${authApiUrl}/v2/personal-data/email/status`),
  putChangeEmail: (newMail) => api.put(
    `${authApiUrl}/v2/personal-data/email`,
    {
      newEmail: newMail,
    },
  ),
  validateChangeMail: (token) => api.post(`${authApiUrl}/v2/account/validateUpdateEmail`, { token }),

  checkIsAlreadyUsedEmail: (email) => api.get(`${authApiUrl}/v2/personal-data/email/checkIsAlreadyUsed`, { params: { email } }),
  getSchoolContracts,
  getProfiles,
  getSchoolContract: async () => {
    const { data: profilesData } = await getProfiles()
    const { profileId, profiles, schoolId } = profilesData
    const profile = profiles.find(({ id }) => id === profileId)
    const schoolProfile = profile.schools.find(({ id }) => id === schoolId)
    const { data: schoolData } = await getSchoolContracts(schoolProfile.code)

    return schoolData.find((school) => school.schoolCode === schoolProfile.code)
  },
}
