import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import api from 'App/api'
import { getProfilePictureLink } from 'App/utils/getProfilePictureLink'
import profilesTypes from 'App/enums/profilesTypes'
import toast from 'App/utils/toast'

import textCommons from 'App/utils/textCommons'
import urls from 'App/utils/urls'
import {
  SET_LOGGED_USER,
} from './actionsTypes'

const useStoreUsers = () => {
  const dispatch = useDispatch()

  const setLoggedUser = useCallback((user) => {
    dispatch({
      type: SET_LOGGED_USER,
      payload: user,
    })
  }, [dispatch])

  const setLoggedUserByData = useCallback((personalData, currentUser) => {
    const {
      id: accountId,
      user,
      profileId,
      profileName,
      schoolId,
      schoolName,
      schoolProspection,
      accessKeyCode,
      userEmail,
    } = personalData

    const {
      grades,
    } = currentUser

    const gradeId = grades?.map(({ id }) => id)

    const { profilePicture, name, profilePictureHeader } = user
    window.dataLayer?.push({
      accountId,
      schoolId,
      profileId,
      gradeId,
      prospection: schoolProspection,
    })

    const gradeName = grades?.map((grade) => grade?.name ?? '')

    dispatch({
      type: SET_LOGGED_USER,
      payload: {
        accessKeyCode,
        accountId,
        profilePicture,
        profilePictureLink: getProfilePictureLink(profilePicture),
        user,
        userEmail,
        profilePictureHeader,
        name,
        profileId,
        profileName,
        schoolId,
        schoolName,
        isStudent: profileId === profilesTypes.student,
        isTeacher: profileId === profilesTypes.teacher,
        gradeId,
        gradeName,
      },
    })
  }, [dispatch])

  const getUserInfo = useCallback(async () => {
    try {
      const [
        { data: personalDataResponseData },
        { data: currentUserResponseData },
      ] = await Promise.all([
        api.get(`${urls.AUTH_API_V2}/personal-data`),
        api.get(`${urls.AUTH_API}/v1/users/me`),
      ])

      setLoggedUserByData(personalDataResponseData, currentUserResponseData)
    } catch (error) {
      toast.error(textCommons.errors.generic)
    }
  }, [setLoggedUserByData])

  return {
    setLoggedUser,
    setLoggedUserByData,
    getUserInfo,
  }
}

export default useStoreUsers
