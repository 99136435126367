import PropTypes from 'prop-types'
import { noop } from 'lodash-es'

import { MenuProvider } from './MenuContext'

import Base from './Base'

const Menu = ({
  onGetPersonalData,
  ...remainingProps
}) => (
  <MenuProvider
    onGetPersonalData={onGetPersonalData}
    {...remainingProps}
  >
    <Base />
  </MenuProvider>
)

Menu.propTypes = {
  onGetPersonalData: PropTypes.func,
}

Menu.defaultProps = {
  onGetPersonalData: noop,
}

export default Menu
