import { createElement } from 'react'
import PropTypes from 'prop-types'

import styles from './toast.module.scss'

const Icon = ({ as }) => (as ? createElement(as, { className: styles.icon }) : null)

Icon.propTypes = {
  as: PropTypes.node,
}

Icon.defaultProps = {
  as: null,
}

export default Icon
