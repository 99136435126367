import { forwardRef } from 'react'
import PropTypes from 'prop-types'

import { noop } from 'App/utils'
import { Spinner } from '@sas-te/alfabeto-react'
import Modal from 'App/components/Modal' // TODO REPLACE FOR ALFABETO MODAL

import styles from './DocumentModal.module.scss'

const DocumentModal = forwardRef(function DocumentalModal(
  {
    documentText,
    isLoading,
    isOpen,
    onClose,
    ...remainingProps
  },
  ref,
) {
  /* eslint-disable react/no-danger */
  return (
    <Modal
      ref={ref}
      className={styles.documentModal}
      isOpen={isOpen}
      onClose={onClose}
      {...remainingProps}
    >
      {isLoading && <Spinner />}

      {!isLoading && documentText && (
        <div
          className={styles.documentText}
          dangerouslySetInnerHTML={{ __html: documentText }}
        />
      )}

    </Modal>
  )
})

DocumentModal.propTypes = {
  documentText: PropTypes.string,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

DocumentModal.defaultProps = {
  documentText: '',
  isLoading: false,
  isOpen: false,
  onClose: noop,
}

export default DocumentModal
