import { Check as CheckIcon } from 'react-feather'

import styles from './SelectedIcon.module.scss'

const SelectedIcon = () => (
  <span className={styles.selected}>
    <CheckIcon className={styles.icon} />
  </span>
)

export default SelectedIcon
