import { SET_USER_MENU, CLEAR_STORE } from './actionsTypes'

export function setUserMenu(userMenu) {
  return {
    type: SET_USER_MENU,
    userMenu,
  }
}

export function clearStore(state) {
  return {
    type: CLEAR_STORE,
    state,
  }
}
