import PropTypes from 'prop-types'
import clsx from 'clsx'

import { noop } from 'App/utils'
import textCommons from 'App/utils/textCommons'

import Button from 'App/components/Button'

import styles from './SelectActions.module.scss'

const SelectActions = ({
  cancelText,
  className,
  confirmText,
  onCancel,
  onConfirm,
}) => (
  <div
    className={clsx(
      styles.actions,
      className,
    )}
  >
    <Button
      className={styles.selectAction}
      variation="tertiary"
      onClick={onCancel}
    >
      {cancelText}
    </Button>

    <Button
      className={clsx(styles.selectAction, styles.confirm)}
      onClick={onConfirm}
    >
      {confirmText}
    </Button>
  </div>
)

SelectActions.propTypes = {
  cancelText: PropTypes.string,
  className: PropTypes.string,
  confirmText: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
}

SelectActions.defaultProps = {
  cancelText: textCommons.actions.cancel,
  className: null,
  confirmText: textCommons.actions.confirm,
  onCancel: noop,
  onConfirm: noop,
}

export default SelectActions
