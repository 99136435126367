import api from 'App/api'
import urls from 'App/utils/urls'

const portalApiUrl = urls.PORTAL_API_URL
const authApiUrl = urls.AUTH_API

const service = {
  getMenus: () => api.get(`${portalApiUrl}/usermenu`),
  getSpecificMenu: () => api.get(`${portalApiUrl}/sidemenu`),
  getPersonalData: () => api.get(`${authApiUrl}/v2/personal-data`),
  getProfiles: () => api.get(`${authApiUrl}/v2/users/me/profiles`),
  getCurrentUser: () => api.get(`${authApiUrl}/v1/users/me`),
}

export default service
