import { useEffect } from 'react'

import keyCodes from 'App/enums/keyCodes'

const useOnEscape = (isActive, handle) => {
  useEffect(() => {
    const handleEscKey = (event) => {
      const key = event.which || event.keyCode

      if (key === keyCodes.esc) {
        event.stopPropagation()
        handle(event)
      }
    }

    if (isActive) {
      document.addEventListener('keyup', handleEscKey)
    }

    return () => {
      document.removeEventListener('keyup', handleEscKey)
    }
  }, [
    handle,
    isActive,
  ])
}

export default useOnEscape
