import Spinner from 'App/components/Spinner/Spinner'

import textCommons from 'App/utils/textCommons'

import styles from './Loading.module.scss'

const Loading = () => (
  <div className={styles.box}>
    <Spinner className={styles.loadingIcon} />

    {`${textCommons.loading}...`}
  </div>
)

export default Loading
