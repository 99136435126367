import {
  SET_LOGGED_USER,
} from './actionsTypes'

const INITIAL_STATE = {
  loggedUser: {},
}

const users = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case SET_LOGGED_USER: {
    return {
      ...state,
      loggedUser: action.payload,
    }
  }
  default:
    return state
  }
}

export default users
