export const hasOwnProperty = (object, prop) => Object.prototype.hasOwnProperty.call(object, prop)

export const filterObjectKeys = (object, keys) => {
  const filtered = {}

  Object.keys(object).forEach((key) => {
    if (keys.includes(key)) {
      filtered[key] = object[key]
    }
  })

  return filtered
}

export const simpleObjectDifference = (object, values, keeps = []) => {
  const difference = {}

  Object.keys(values).forEach((key) => {
    if (values[key] !== object[key] || keeps.includes(key)) {
      difference[key] = values[key]
    }
  })

  return difference
}

export const objectIsEmpty = (object) => {
  const isEmpty = Reflect.ownKeys(object).length === 0 && object.constructor === Object

  return isEmpty
}
