export const XS = 375
export const SM = 576
export const MD = 768
export const LG = 992
export const XL = 1200
export const XXL = 1600

const breakpoints = [
  XS,
  SM,
  MD,
  LG,
  XL,
]

export default breakpoints
