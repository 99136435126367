import { CUSTOM_EVENT_ANALYTICS, tutorialGTM } from 'App/hooks/useGTM'
import tokens from '@sas-te/alfabeto-tokens/dist/tokens.json'
import { setOnboarding } from 'App/store/onboarding/actions'
import { cloneDeep } from 'lodash-es'

export const getPopoverStyle = (base) => ({
  ...{
    ...base,
    padding: 0,
    paddingTop: tokens.size_s,
    maxWidth: '425px',
    borderRadius: tokens.border_radius_m,
    fontFamily: tokens.font_family_primary,
    fontSize: tokens.font_size_s,
    lineHeight: tokens.line_height_text,
    '@media all and (max-width: 576px)': {
      maxWidth: '95%',
    },
  },
})

export const getMaskAreaStyle = (props) => ({
  ...props,
  ...{
    rx: 8,
    ry: 8,
  },
})

export const getCloseStyle = (base) => ({
  ...{
    ...base,
    width: '15px',
    height: '15px',
    svg: {
      width: '10px',
      height: '10px',
    },
    '&:focus': {
      outline: 'none',
    },
  },
})

export const setOnboardingUserLocalStorage = ({ onboarding }, users, dispatch, page) => {
  const onboardingCloned = cloneDeep(onboarding)
  const userId = users?.loggedUser?.user?.id

  onboardingCloned[page].users[userId] = {
    id: userId,
  }

  document.body.style.overflow = 'auto'
  dispatch(setOnboarding(onboardingCloned))
}

export const closeOnboarding = ({
  setIsOpen, dispatch, onboarding, users, page, text,
}) => {
  setOnboardingUserLocalStorage(onboarding, users, dispatch, page)
  setIsOpen(false)
  if (text === 'Sair do tutorial') {
    // the function does not support hook GTM
    window.dataLayer.push({
      event: CUSTOM_EVENT_ANALYTICS,
      category: tutorialGTM.category,
      action: tutorialGTM.action.sair,
    })
  } else {
    // the function does not support hook GTM
    window.dataLayer.push({
      event: CUSTOM_EVENT_ANALYTICS,
      category: tutorialGTM.category,
      action: tutorialGTM.action.fecharX,
    })
  }
}
