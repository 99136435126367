import PropTypes from 'prop-types'
import clsx from 'clsx'
import { v4 as uuid } from 'uuid'
import { Info as InfoIcon } from 'react-feather'

import Tooltip from '../Tooltip'

import styles from './InputLabel.module.scss'

const InputLabel = ({
  children,
  className,
  information,
  informationIcon,
  inputId,
  ...remainingProps
}) => {
  const tooltipId = `${inputId || uuid()}-tooltip`

  const label = children && (
    <label
      className={clsx(
        styles.label,
        'sas-input-label',
        className,
      )}
      htmlFor={inputId}
      {...remainingProps}
    >
      {children}

      {information && (
        <>
          <span
            className={clsx(
              styles.labelInfoIcon,
              'sas-input-label-info-icon',
            )}
            data-for={tooltipId}
            data-tip
          >
            {informationIcon}
          </span>

          <Tooltip id={tooltipId}>
            {information}
          </Tooltip>
        </>
      )}
    </label>
  )

  return label
}

InputLabel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  information: PropTypes.string,
  informationIcon: PropTypes.node,
  inputId: PropTypes.string,
}

InputLabel.defaultProps = {
  children: null,
  className: null,
  information: null,
  informationIcon: <InfoIcon />,
  inputId: null,
}

export default InputLabel
