import { useState, useRef } from 'react'
import { usePopper } from 'react-popper'

import useOnClickOutside from 'App/hooks/useOnClickOutside'
import useOnEscape from 'App/hooks/useOnEscape'
import { sameWidthModifier } from 'App/utils/popper'

import useMenuContext from '../hooks/useMenuContext'

import ProfileInfo from './ProfileInfo'
import ProfileDetails from './ProfileDetails'
import ProfileSpecificMenu from './ProfileSpecificMenu'
import ProfileMenu from './ProfileMenu'

import styles from './Profile.module.scss'

const dropdownConfig = {
  placement: 'bottom-end',
  strategy: 'fixed',
  modifiers: [sameWidthModifier],
}

const Profile = () => {
  const { isDesktopMediaQuery } = useMenuContext()

  const [
    isOpen,
    setOpen,
  ] = useState(false)

  const [
    triggerDropdownReference,
    setTriggerDropdownReference,
  ] = useState(null)
  const [
    dropdownReference,
    setDropdownReference,
  ] = useState(null)

  const [
    isProfileModalOpen,
    setProfileModalOpen,
  ] = useState(false)

  const openProfileModal = () => {
    setProfileModalOpen(true)
  }

  const closeProfileModal = () => {
    setProfileModalOpen(false)
  }

  const containerRef = useRef()

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    triggerDropdownReference,
    dropdownReference,
    dropdownConfig,
  )

  const dropdownStyles = isDesktopMediaQuery ? popperStyles.popper : null
  const dropdownAttributes = isDesktopMediaQuery ? popperAttributes.popper : {}

  const toggle = () => {
    setOpen(!isOpen)
  }

  const close = () => {
    setOpen(false)
  }

  useOnClickOutside(isOpen && !isProfileModalOpen, containerRef.current, close)
  useOnEscape(isOpen && !isProfileModalOpen, close)

  return (
    <div
      ref={containerRef}
      className={styles.profile}
    >
      <ProfileInfo
        ref={setTriggerDropdownReference}
        onClick={toggle}
      />

      {isOpen && (
        <div
          ref={setDropdownReference}
          className={styles.dropdown}
          style={dropdownStyles}
          {...dropdownAttributes}
        >
          <ProfileDetails />

          <ProfileSpecificMenu />

          <ProfileMenu
            isModalOpen={isProfileModalOpen}
            onCloseModal={closeProfileModal}
            onOpenModal={openProfileModal}
          />
        </div>
      )}
    </div>
  )
}

export default Profile
