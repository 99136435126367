import {
  useCallback,
  useState,
} from 'react'

import service from '../service'

const useFetchPersonalData = ({
  onGetPersonalData,
}) => {
  const [
    personalData,
    setPersonalData,
  ] = useState(null)
  const [
    isLoadingPersonalData,
    setLoadingPersonalData,
  ] = useState(true)
  const [
    hasLoadingPersonalDataError,
    setLoadingPersonalDataError,
  ] = useState(false)

  const getPersonalData = useCallback(async () => {
    setPersonalData(null)
    setLoadingPersonalData(true)
    setLoadingPersonalDataError(false)

    try {
      const [
        { data: personalDataResponseData },
        { data: currentUserResponseData },
      ] = await Promise.all([
        service.getPersonalData(),
        service.getCurrentUser(),
      ])

      setPersonalData(personalDataResponseData)
      onGetPersonalData(personalDataResponseData, currentUserResponseData)
    } catch {
      setLoadingPersonalDataError(true)
    } finally {
      setLoadingPersonalData(false)
    }
  }, [onGetPersonalData])

  return {
    personalData,
    isLoadingPersonalData,
    hasLoadingPersonalDataError,
    getPersonalData,
  }
}

export default useFetchPersonalData
