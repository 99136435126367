import { forwardRef } from 'react'
import clsx from 'clsx'

import { MEDIUM } from 'App/utils/configurations'

import Image from 'App/components/Image'
import { User as UserIcon } from 'react-feather'
import avatarProptypes from './avatarProptypes'

import styles from './Avatar.module.scss'

const Avatar = forwardRef(function Avatar({
  className,
  imageSrc,
  size,
  text,
  ...remainingProps
}, ref) {
  const sizeClasses = {
    small: [
      styles.isSmall,
      '--small',
    ],
    medium: [
      styles.isMedium,
      '--medium',
    ],
    large: [
      styles.isLarge,
      '--large',
    ],
  }

  const hasText = Boolean(text.length)
  const getContent = () => {
    if (hasText) {
      return text.charAt(0)
    }

    return <UserIcon />
  }

  return (
    <div
      ref={ref}
      className={clsx(
        styles.avatar,
        sizeClasses[size],
        'sas-avatar',
        className,
      )}
      {...remainingProps}
    >
      {imageSrc && (
        <Image
          alt={text}
          className={styles.image}
          src={imageSrc}
        />
      )}

      <span className={clsx(
        styles.content,
        { [styles.isIcon]: !hasText },
      )}
      >
        {getContent()}
      </span>
    </div>
  )
})

Avatar.propTypes = avatarProptypes

Avatar.defaultProps = {
  className: null,
  imageSrc: '',
  size: MEDIUM,
  text: '',
}

export default Avatar
