import {
  SET_LOADING,
} from './actionsTypes'

const INITIAL_STATE = {
  isLoading: false,
}

const quickAccess = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case SET_LOADING: {
    return {
      ...state,
      isLoading: action.isLoading,
    }
  }
  default:
    return state
  }
}

export default quickAccess
