/* eslint-disable no-param-reassign */
export const sameWidthModifier = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`
  },
  effect: ({ state }) => {
    state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`
  },
}
/* eslint-enable no-param-reassign */

export default {
  modifiers: {
    sameWidth: sameWidthModifier,
  },
}
