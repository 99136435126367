import PropTypes from 'prop-types'
import { AlertCircle as AlertCircleIcon } from 'react-feather'

import styles from './FormTextError.module.scss'

const FormTextError = ({ children, id }) => (
  <div className={styles.wrapper}>
    <div>
      <AlertCircleIcon size="16" />
    </div>

    <div
      className={styles.text}
      id={id}
      role="alert"
    >
      {children}
    </div>
  </div>
)

FormTextError.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
}

FormTextError.defaultProps = {
  children: null,
  id: null,
}

export default FormTextError
