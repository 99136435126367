import PropTypes from 'prop-types'
import { portalURL } from 'App/utils/auth'

import styles from './withoutMenu.module.scss'

const logoURL = `${portalURL}/img/logo-sas.svg`

const WithoutMenuLayout = ({
  children,
}) => (
  <div className={styles.defaultLayout}>
    <header className={styles.appHeader}>
      <a
        className={styles.link}
        href="/"
      >
        <img
          alt="SAS Plataforma de Educação"
          className={styles.logo}
          src={logoURL}
          title="SAS Plataforma de Educação"
        />
      </a>
    </header>

    <div className={styles.content}>
      {children}
    </div>

    <footer className={styles.appFooter}>
      <span className={styles.footerRights}>
        Todos os direitos reservados – SAS Plataforma de Educação
      </span>
    </footer>
  </div>
)

WithoutMenuLayout.propTypes = {
  children: PropTypes.node,
}
WithoutMenuLayout.defaultProps = {
  children: null,
}
export default WithoutMenuLayout
