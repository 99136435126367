import { compose, createStore } from 'redux'

import rootReducer from './rootReducer'

/* eslint-disable no-underscore-dangle */
const composeEnhancers = process.env.REACT_APP_MODE === 'development'
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  : compose
/* eslint-enable */

export default createStore(
  rootReducer,
  composeEnhancers(),
)
