import PropTypes from 'prop-types'
import { noop } from 'lodash-es'

import SchoolOption from '../SchoolOption'

import styles from './SchoolsList.module.scss'

const SchoolsList = ({
  onSelect,
  schools,
  selectedSchool,
}) => {
  const handleSelectSchool = (school) => () => {
    onSelect(school)
  }

  return (
    <div className={styles.schoolsList}>
      {schools.map((school) => (
        <SchoolOption
          key={school.id}
          isActive={selectedSchool?.id === school.id}
          name={school.name}
          onClick={handleSelectSchool(school)}
        />
      ))}
    </div>
  )
}

SchoolsList.propTypes = {
  onSelect: PropTypes.func,
  schools: PropTypes.arrayOf(PropTypes.object),
  selectedSchool: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }),
}

SchoolsList.defaultProps = {
  onSelect: noop,
  schools: [],
  selectedSchool: null,
}

export default SchoolsList
