import React, { useState } from 'react'
import Modal from 'App/components/Modal'
import { Widget } from '@typeform/embed-react'
import { PropTypes } from 'prop-types'
import { useSelector } from 'react-redux'
import { Star as StarIcon } from 'react-feather'
import profilesTypes from 'App/enums/profilesTypes'
import api from 'App/api'

import urls from 'App/utils/urls'
import styles from './RatePortalButton.module.scss'
import ProfileMenuLink from '../../Profile/ProfileMenuLink'

const RatePortalButton = ({ textButton, surveyId }) => {
  const { loggedUser } = useSelector((state) => state.users)

  const [
    loading,
    setLoading,
  ] = useState(false)

  const [
    hiddenFields,
    setHiddenFields,
  ] = useState(null)

  const [
    isModalCSATOpened,
    setIsModalCSATOpened,
  ] = useState(false)

  const openModalCSAT = async () => {
    setIsModalCSATOpened(true)

    const hiddenFieldsObj = {
      account_id: loggedUser.accountId,
      profile: loggedUser.profileName,
      url: window.location.href,
    }

    if (
      [
        profilesTypes.student,
        profilesTypes.teacher,
      ].includes(loggedUser.profileId)
    ) {
      try {
        setLoading(true)
        const { data: currentUserData } = await api.get(`${urls.AUTH_API}/v1/users/me`)
        const grades = currentUserData.grades.map((grade) => grade.name).join()

        hiddenFieldsObj.grade = grades
      } finally {
        setLoading(false)
      }
    }

    setHiddenFields(hiddenFieldsObj)
  }

  const closeModalCSAT = () => {
    setIsModalCSATOpened(false)
  }

  const getButton = () => (
    <ProfileMenuLink
      icon={StarIcon}
      role="menuitem"
      onClick={openModalCSAT}
    >
      {textButton}
    </ProfileMenuLink>
  )

  const getModal = () => (
    <Modal
      className={styles.modalCSAT}
      isOpen={isModalCSATOpened}
      onClose={closeModalCSAT}
    >
      {!loading && (
        <Widget
          className={styles.modalCSATBody}
          hidden={hiddenFields}
          id={surveyId}
          opacity={0}
          hideScrollbars
          inlineOnMobile
        />
      )}
    </Modal>
  )

  return (
    <div>
      {getButton()}

      {getModal()}
    </div>
  )
}
RatePortalButton.defaultProps = {
  textButton: 'Avalie o portal',
}

RatePortalButton.propTypes = {
  textButton: PropTypes.string,
  surveyId: PropTypes.string.isRequired,
}

export default RatePortalButton
