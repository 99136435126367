import PropTypes from 'prop-types'
import clsx from 'clsx'

import styles from './SelectableInputLabel.module.scss'

const SelectableInputLabel = ({
  children,
  className,
  htmlFor,
  id,
  isDisabled,
  hasNoMargin,
}) => (
  <label
    className={clsx(
      {
        [styles.label]: !hasNoMargin,
      },
      {
        [styles.disabled]: isDisabled,
      },
      className,
    )}
    htmlFor={htmlFor}
    id={id}
  >
    {children}
  </label>
)

SelectableInputLabel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  htmlFor: PropTypes.string.isRequired,
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  hasNoMargin: PropTypes.bool,
}

SelectableInputLabel.defaultProps = {
  children: null,
  className: null,
  hasNoMargin: false,
  id: null,
  isDisabled: false,
}

export default SelectableInputLabel
