import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getProfileAvatarAndCoverDefautlLink } from 'App/utils/getProfileAvatarAndCoverDefautlLink'
import styles from './ProfileImage.module.scss'

const ProfileImage = ({ personalData }) => {
  const { loggedUser } = useSelector((state) => state.users)
  const profilePicture = useMemo(
    () => `${loggedUser.profilePictureLink || getProfileAvatarAndCoverDefautlLink('avatarIcon')}`,
    [loggedUser.profilePictureLink],
  )
  const profilePictureExists = profilePicture !== ''

  const firstUserNameLetter = useMemo(() => {
    const user = personalData?.user || null

    if (user?.name) {
      return user.name
        .trim()
        .slice(0, 1)
        .toUpperCase()
    }

    return ''
  }, [personalData])

  if (profilePictureExists) {
    return (
      <div>
        <img
          alt=""
          className={styles.profileImage}
          src={profilePicture}
        />
      </div>
    )
  }

  return (
    <div className={styles.profileImage}>
      {firstUserNameLetter}
    </div>
  )
}

ProfileImage.propTypes = {
  personalData: PropTypes.shape({
    user: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
}

ProfileImage.defaultProps = {
  personalData: {},
}

export default ProfileImage
