import { X as CloseIcon } from 'react-feather'
import PropTypes from 'prop-types'

import Button from 'App/components/Button'

import textCommons from 'App/utils/textCommons'

import styes from './toast.module.scss'

const CloseButton = ({ closeToast }) => (
  <Button
    aria-label={textCommons.actions.close}
    className={styes.closeButton}
    icon={(
      <CloseIcon
        height={20}
        width={20}
      />
    )}
    size="small"
    variation="tertiary"
    onClick={closeToast}
  />
)

CloseButton.propTypes = {
  closeToast: PropTypes.func.isRequired,
}

export default CloseButton
