import { useState } from 'react'
import PropTypes from 'prop-types'

const Image = ({
  alt,
  src,
  srcFallback,
  ...remainingProps
}) => {
  const [
    hasError,
    setError,
  ] = useState(false)
  const [
    internalSrc,
    setInternalSrc,
  ] = useState(src)
  const handleError = () => {
    if (!hasError) {
      setInternalSrc(srcFallback)
      setError(true)
    }
  }

  if (hasError && !srcFallback) {
    return null
  }

  return (
    <img
      alt={alt}
      src={internalSrc}
      onError={handleError}
      {...remainingProps}
    />
  )
}

Image.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string.isRequired,
  srcFallback: PropTypes.string,
}

Image.defaultProps = {
  alt: '',
  srcFallback: null,
}

export default Image
