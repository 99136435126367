import { lazy } from 'react'

export default [
  {
    path: '/',
    component: lazy(() => import('./Home')),
    name: 'home',
    exact: true,
    meta: {
      allowAnonymous: true,
    },
  },
]
