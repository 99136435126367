import {
  SET_ZENDESK_LINK,
} from './actionsTypes'

const INITIAL_STATE = {
  link: null,
}

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case SET_ZENDESK_LINK: {
    return {
      ...state,
      link: action.link,
    }
  }
  default:
    return state
  }
}
