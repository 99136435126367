import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { v4 as uuid } from 'uuid'

import { noop } from 'App/utils'
import { MEDIUM, sizes } from 'App/utils/configurations'

import SelectableInputLabel from 'App/components/SelectableInputLabel'

import styles from './Radio.module.scss'

const Radio = forwardRef(function Radio({
  className,
  hasFullWidth,
  id,
  inputRef,
  isChecked,
  isDisabled,
  label,
  name,
  size,
  onChange,
  ...remainingProps
}, ref) {
  const inputId = id || `sas-radio-${uuid()}`

  const classes = {
    isDisabled: '--disabled',
    hasFullWidth: '--full-width',
    size: `--${size}`,
  }

  return (
    <div
      ref={ref}
      className={clsx(
        styles.radio,
        styles[classes.size],
        classes.size,
        isDisabled && [
          styles[classes.isDisabled],
          classes.isDisabled,
        ],
        hasFullWidth && [
          styles[classes.hasFullWidth],
          classes.hasFullWidth,
        ],
        'sas-radio',
        className,
      )}
    >
      <input
        ref={inputRef}
        checked={isChecked}
        className={clsx(
          styles.radioField,
          'sas-radio-field',
        )}
        disabled={isDisabled}
        id={inputId}
        name={name}
        type="radio"
        onChange={onChange}
        {...remainingProps}
      />

      {label && (
        <SelectableInputLabel
          className="sas-radio-label"
          htmlFor={inputId}
          isDisabled={isDisabled}
        >
          {label}
        </SelectableInputLabel>
      )}
    </div>
  )
})

Radio.propTypes = {
  className: PropTypes.string,
  hasFullWidth: PropTypes.bool,
  id: PropTypes.string,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(sizes),
}

Radio.defaultProps = {
  className: null,
  hasFullWidth: false,
  id: null,
  inputRef: null,
  isChecked: null,
  isDisabled: false,
  label: null,
  name: null,
  onChange: noop,
  size: MEDIUM,
}

export default Radio
