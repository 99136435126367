import PropTypes from 'prop-types'
import { noop } from 'lodash-es'
import clsx from 'clsx'

import SelectedIcon from '../../SelectedIcon'

import styles from './SchoolOption.module.scss'

const SchoolOption = ({
  isActive,
  name,
  onClick,
}) => (
  <button
    className={clsx(
      styles.option,
      { [styles.active]: isActive },
    )}
    type="button"
    onClick={onClick}
  >
    <div className={styles.initial}>
      {name.substr(0, 1)}
    </div>

    <div className={styles.name}>
      {name}
    </div>

    {isActive && <SelectedIcon />}
  </button>
)

SchoolOption.propTypes = {
  isActive: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

SchoolOption.defaultProps = {
  isActive: false,
  onClick: noop,
}

export default SchoolOption
