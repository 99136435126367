export const focusableQuerySelectors = [
  'a[href]',
  'area[href]',
  'input:not([disabled])',
  'select:not([disabled])',
  'textarea:not([disabled])',
  'button:not([disabled])',
  'iframe',
  'object',
  'embed',
  '*[tabindex]',
  '*[contenteditable]',
].join(', ')

export const isFocusable = (element) => {
  if (
    element?.tabIndex > 0
    || (element.tabIndex === 0 && element.getAttribute('tabIndex') !== null)
  ) {
    return true
  }

  if (element.disabled || element['aria-disabled']) {
    return false
  }

  switch (element.nodeName) {
  case 'A':
    return !!element.href && element.rel !== 'ignore'
  case 'INPUT':
    return element.type !== 'hidden' && element.type !== 'file'
  case 'SELECT':
  case 'TEXTAREA':
    return true
  default:
    return false
  }
}
