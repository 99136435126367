import { forwardRef } from 'react'
import PropTypes from 'prop-types'

import { noop } from 'App/utils'

import Option from 'App/components/Select/Option'

import optionPropTypeShape from './utils/optionPropTypeShape'
import selectStates from './utils/selectStatesEnum'

import styles from './SelectOptions.module.scss'

const SelectOptions = forwardRef(function SelectOptions({
  firstSelectedOptionRef,
  isActionsEnabled,
  isGrouped,
  isMultiple,
  onUpdate,
  options,
  selectedOptions,
}, ref) {
  const handleSelect = (option) => () => {
    const updatedSelectedValues = isMultiple ? [
      ...selectedOptions,
      option,
    ] : [option]

    onUpdate(updatedSelectedValues)
  }

  const handleUnselectOption = (option) => () => {
    const updatedSelectedValues = selectedOptions.filter(({ $value }) => $value !== option.$value)

    onUpdate(updatedSelectedValues)
  }

  const toggleStatus = (items, isAllChecked) => () => {
    let newSelectedOptions

    if (isAllChecked) {
      newSelectedOptions = selectedOptions
        .filter((selectedOption) => items
          .some(({ $value }) => selectedOption.$value === $value))
    } else {
      newSelectedOptions = [
        ...selectedOptions,
        ...items,
      ]
    }

    onUpdate(newSelectedOptions)
  }

  const getSelectOptionComponent = (option) => {
    const otherProps = {}
    const isSelected = selectedOptions
      .some((selectedOption) => selectedOption.$value === option.$value)

    if (isSelected && !firstSelectedOptionRef?.current) {
      otherProps.ref = firstSelectedOptionRef
    }

    return (
      <Option
        key={option.$value}
        avatar={option.avatar}
        isControlVisible={isActionsEnabled}
        isDisabled={option.isDisabled}
        isMultiple={isMultiple}
        isSelected={isSelected}
        subText={option.subText}
        text={option.$text}
        onSelect={handleSelect(option)}
        onUnselect={handleUnselectOption(option)}
        {...otherProps}
      >
        {isSelected && option.selectedDetailsCallable?.()}
      </Option>
    )
  }

  const getGroupCheckedStatus = (_options) => {
    let checkedCount = 0

    _options
      .forEach((option) => {
        if (selectedOptions.some(({ $value }) => $value === option.$value)) {
          checkedCount += 1
        }
      })

    if (checkedCount === _options.length) {
      return selectStates.checked
    }

    if (checkedCount > 0) {
      return selectStates.mixed
    }

    return selectStates.unchecked
  }

  return (
    <ul
      ref={ref}
      aria-multiselectable={isMultiple}
      className={styles.options}
      role="listbox"
    >
      {isGrouped
        ? options.map((option, index) => {
          const { items } = option
          const text = option.$text
          const optionStatus = isMultiple && getGroupCheckedStatus(option)

          return (
            <Option
              key={option.$value}
              isIndeterminate={optionStatus === selectStates.mixed}
              isMultiple={isMultiple}
              isSelected={optionStatus === selectStates.checked}
              labelId={styles.options + index}
              text={text}
              isGrouped
              onClick={toggleStatus(
                items,
                optionStatus === selectStates.checked,
              )}
            >
              <ul
                aria-multiselectable={isMultiple}
                role="listbox"
              >
                {items.map(getSelectOptionComponent)}
              </ul>
            </Option>
          )
        })
        : options.map(getSelectOptionComponent)}
    </ul>
  )
})

SelectOptions.propTypes = {
  firstSelectedOptionRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  isActionsEnabled: PropTypes.bool,
  isGrouped: PropTypes.bool,
  isMultiple: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  onUpdate: PropTypes.func,
  selectedOptions: PropTypes.arrayOf(PropTypes.shape({
    ...optionPropTypeShape,
    items: PropTypes.arrayOf(PropTypes.shape(optionPropTypeShape)),
  })),
}

SelectOptions.defaultProps = {
  firstSelectedOptionRef: null,
  isActionsEnabled: false,
  isGrouped: false,
  isMultiple: false,
  onUpdate: noop,
  options: [],
  selectedOptions: [],
}

export default SelectOptions
