import PropTypes from 'prop-types'
import { noop } from 'lodash-es'

import ProfileCard from '../ProfileCard'

import styles from './ProfileSelector.module.scss'

const ProfileSelector = ({
  onSelect,
  profiles,
  selectedProfile,
}) => {
  const handleSelectProfile = (profile) => () => {
    onSelect(profile)
  }

  return (
    <div className={styles.multiProfileSelector}>
      <div className={styles.containerProfile}>
        {profiles.map((profile) => (
          <ProfileCard
            key={profile.id}
            className={styles.profileCard}
            id={profile.id}
            isSelected={selectedProfile?.id === profile.id}
            name={profile.name}
            onClick={handleSelectProfile(profile)}
          />
        ))}
      </div>
    </div>
  )
}

ProfileSelector.propTypes = {
  onSelect: PropTypes.func,
  profiles: PropTypes.arrayOf(PropTypes.object),
  selectedProfile: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }),
}

ProfileSelector.defaultProps = {
  onSelect: noop,
  profiles: [],
  selectedProfile: null,
}

export default ProfileSelector
