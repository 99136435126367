import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash-es'
import clsx from 'clsx'

import keyCodes from 'App/enums/keyCodes'
import getProfileTypeIcon from 'App/utils/getProfileTypeIcon'

import SelectedIcon from '../SelectedIcon'

import styles from './ProfileCard.module.scss'

const ProfileCard = ({
  className,
  id,
  isSelected,
  name,
  onClick,
  tabIndex,
}) => {
  const [
    profileTypeImage,
    setProfileTypeImage,
  ] = useState(null)

  const handleKeyPress = (event) => {
    const key = event.which

    if (key === keyCodes.return || key === keyCodes.space) {
      onClick()
    }
  }

  useEffect(() => {
    setProfileTypeImage(getProfileTypeIcon(id))
  }, [id])

  return (
    <div
      className={clsx(
        styles.profileCard,
        { [styles.profileCardSelected]: isSelected },
        className,
      )}
      role="button"
      tabIndex={tabIndex}
      onClick={onClick}
      onKeyPress={handleKeyPress}
    >
      {isSelected && <SelectedIcon />}

      <div className={styles.imgContainer}>
        <img
          alt=""
          className={styles.img}
          src={profileTypeImage}
        />
      </div>

      <div className={styles.titleContainer}>
        <span className={styles.title}>
          {name}
        </span>
      </div>
    </div>
  )
}

ProfileCard.propTypes = {
  className: PropTypes.string,
  id: PropTypes.number.isRequired,
  isSelected: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  tabIndex: PropTypes.number,
}

ProfileCard.defaultProps = {
  className: null,
  isSelected: false,
  onClick: noop,
  tabIndex: 0,
}

export default ProfileCard
