export const isNotEmpty = (array) => Array.isArray(array) && array.length > 0
export const isEmpty = (array) => Array.isArray(array) && array.length === 0
export const arrayUnique = (array) => [...new Set(array)]

export const generateSkeletonKeys = (size) => [...new Array(size)].map((_, k) => `skeleton-key-${k}`)

export const reorder = (list, currentIndex, newIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(currentIndex, 1)
  result.splice(newIndex, 0, removed)

  return result
}

export const arrayMove = ({ arr, fromIndex, toIndex }) => {
  const element = arr[fromIndex]

  arr.splice(fromIndex, 1)
  arr.splice(toIndex, 0, element)

  return arr
}

export const orderedArray = (data, orderingParameter, ordenationType = 'DESC') => {
  const sortedData = data.sort((a, b) => {
    if (ordenationType === 'ASC') {
      if (a[orderingParameter]) {
        if (a[orderingParameter] < b[orderingParameter]) {
          return -1
        }
        if (a[orderingParameter] > b[orderingParameter]) {
          return 1
        }

        return 0
      }
      if (a[orderingParameter] < b[orderingParameter]) {
        return -1
      }
      if (a[orderingParameter] > b[orderingParameter]) {
        return 1
      }

      return 0
    } if (ordenationType === 'DESC') {
      if (b[orderingParameter]) {
        if (b[orderingParameter] < a[orderingParameter]) {
          return -1
        }
        if (b[orderingParameter] > a[orderingParameter]) {
          return 1
        }

        return 0
      }
      if (b[orderingParameter] < a[orderingParameter]) {
        return -1
      }
      if (b[orderingParameter] > a[orderingParameter]) {
        return 1
      }

      return 0
    }

    return null
  })

  return sortedData
}
