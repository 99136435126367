import { TourProvider } from '@reactour/tour'

import {
  getPopoverStyle, getMaskAreaStyle,
  getCloseStyle, closeOnboarding,
} from 'App/components/Onboarding/Tour/tourProviderHelper'
import { noop } from 'App/utils'
import { useDispatch, useSelector } from 'react-redux'
import { PropTypes } from 'prop-types'
import { useLocation } from 'react-router-dom'
import { camelCase } from 'lodash-es'
import Navigation from './Footer/Navigation'

const TourProviderContainer = ({ children }) => {
  const { onboarding, users } = useSelector((state) => state)
  const dispatch = useDispatch()
  const location = useLocation()
  const page = camelCase(location.pathname) || 'home'

  return window.ResizeObserver ? (
    <TourProvider
      components={{
        Navigation,
      }}
      showBadge={false}
      styles={{
        popover: getPopoverStyle,
        maskArea: getMaskAreaStyle,
        close: getCloseStyle,
      }}
      close
      disableInteraction
      onClickClose={(props) => closeOnboarding({
        ...props,
        ...{
          dispatch,
          onboarding,
          users,
          page,
        },
      })}
      onClickHighlighted={noop}
      onClickMask={noop}
    >
      {children}
    </TourProvider>
  ) : (
    <>
      {children}
    </>
  )
}

TourProviderContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TourProviderContainer
