import { getAuthSession } from 'App/utils/auth'

const getIntegrationLink = (menuItem) => {
  const baseLink = menuItem.link
  const queryMark = baseLink.includes('?') ? '&' : '?'
  const sessionToken = getAuthSession()

  return menuItem.isExternalIntegration
    ? `${baseLink}${queryMark}token=${sessionToken}`
    : baseLink
}

export default getIntegrationLink
