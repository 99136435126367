/* eslint-disable react/boolean-prop-naming */
import clsx from 'clsx'
import { noop, camelCase } from 'lodash-es'
import PropTypes from 'prop-types'

import {
  ArrowLeft as PrevIcon,
  ArrowRight as NextIcon,
} from 'react-feather'

import Button from 'App/components/Button'

import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import styles from './Navigation.module.scss'
import { closeOnboarding } from '../tourProviderHelper'

const Navigation = ({
  currentStep,
  disableAll,
  hideButtons,
  hideDots,
  nextButton,
  prevButton,
  setCurrentStep,
  setIsOpen,
  steps,
}) => {
  const { onboarding, users } = useSelector((state) => state)
  const dispatch = useDispatch()
  const location = useLocation()
  const page = camelCase(location.pathname) || 'home'

  const stepsLength = steps.length
  const isSingleStep = stepsLength <= 1
  const isFirstStep = currentStep === 0
  const isLastStep = stepsLength - 1 === currentStep
  const dots = Array.from({ length: stepsLength }, (_, i) => i)

  const navButtonsProps = {
    setCurrentStep,
    currentStep,
    stepsLength,
    setIsOpen,
  }

  const onClickPrev = () => {
    setCurrentStep(Math.max(currentStep - 1, 0))
  }

  const onClickNext = () => {
    setCurrentStep(Math.min(currentStep + 1, stepsLength - 1))
  }

  const PrevButton = () => {
    if (prevButton && typeof prevButton === 'function') {
      return prevButton(navButtonsProps)
    }

    return (
      <Button
        aria-label="Voltar para o passo anterior"
        isDisabled={disableAll}
        prepend={<PrevIcon />}
        variation="secondary"
        onClick={onClickPrev}
      >
        Voltar
      </Button>
    )
  }

  const NextButton = () => {
    if (nextButton && typeof nextButton === 'function') {
      return nextButton(navButtonsProps)
    }

    if (isSingleStep) {
      return (
        <Button
          isDisabled={disableAll}
          onClick={() => closeOnboarding({
            setIsOpen,
            dispatch,
            onboarding,
            users,
            page,
          })}
        >
          Entendi
        </Button>
      )
    }

    if (isLastStep) {
      return (
        <Button
          append={<NextIcon />}
          isDisabled={disableAll}
          onClick={() => closeOnboarding({
            setIsOpen,
            dispatch,
            onboarding,
            users,
            page,
          })}
        >
          Finalizar
        </Button>
      )
    }

    return (
      <Button
        append={<NextIcon />}
        aria-label="Avançar para o próximo passo"
        isDisabled={disableAll}
        onClick={onClickNext}
      >
        Avançar
      </Button>
    )
  }

  return (
    <>
      {!hideDots && !isSingleStep && (
        <div className={styles.dots}>
          {dots.map((dotIndex) => (
            <button
              key={dotIndex}
              aria-label={`Ir para o passo ${dotIndex + 1}`}
              className={clsx(
                styles.dot,
                { [styles.dotActive]: dotIndex === currentStep },
              )}
              disabled={disableAll}
              type="button"
              onClick={() => setCurrentStep(dotIndex)}
            />
          ))}
        </div>
      )}

      {!hideButtons && (
        <div className={styles.navigation}>
          <div className={styles.endCoachMarkContainer}>
            {(isSingleStep || !isLastStep) && (
              <Button
                className={styles.endCoachMark}
                isDisabled={disableAll}
                variation="tertiary"
                onClick={() => closeOnboarding({
                  setIsOpen,
                  dispatch,
                  onboarding,
                  users,
                  page,
                  text: 'Sair do tutorial',
                })}
              >
                Sair do tutorial
              </Button>
            )}
          </div>

          {!isSingleStep && !isFirstStep && (
            <div>
              <PrevButton />
            </div>
          )}

          <div>
            <NextButton />
          </div>
        </div>
      )}
    </>
  )
}

Navigation.propTypes = {
  currentStep: PropTypes.number,
  disableAll: PropTypes.bool,
  hideButtons: PropTypes.bool,
  hideDots: PropTypes.bool,
  nextButton: PropTypes.func,
  prevButton: PropTypes.func,
  setCurrentStep: PropTypes.func,
  setIsOpen: PropTypes.func,
  steps: PropTypes.arrayOf(PropTypes.object),
}

Navigation.defaultProps = {
  currentStep: 0,
  disableAll: false,
  hideButtons: false,
  hideDots: false,
  nextButton: null,
  prevButton: null,
  setCurrentStep: noop,
  setIsOpen: noop,
  steps: [],
}

export default Navigation
