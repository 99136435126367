export const CUSTOM_EVENT_ANALYTICS = 'custom_event_analytics'

export const homeGTM = {
  category: 'Home',
  action: {
    editarAvatar: 'EditarAvatar',
    editarAcessos: 'EditarAcessos',
    cliqueBanner: 'CliqueBanner',
    reverTutorial: 'ReverTutorial',
    editarCapa: 'EditarCapa',
    proximoBanner: 'ProximoBanner',
    anteriorBanner: 'AnteriorBanner',
    ajuda: 'Ajuda',
    acessosRapidos: 'AcessosRapidos',
    redesSociais: 'RedesSociais',

  },
}

export const menuGTM = {
  category: 'Menu',
}

export const meusAcessosGTM = {
  category: 'MeusAcessos',
  action: {
    mover: 'Mover',
    removerAtalho: 'RemoverAtalho',
    action: 'Opções',
    cancelar: 'Cancelar',
    salvar: 'Salvar',
    fechar: 'Fechar',
  },
  label: {
    moverEsquerda: 'MoverEsquerda',
    moverDireita: 'MoverDireita',
  },
}

export const editarPersonalizacaoGTM = {
  category: 'EditarPersonalizacao',
  action: {
    editarAvatar: 'EditarAvatar',
    editarCapa: 'EditarCapa',
    cancelar: 'Cancelar',
    salvar: 'Salvar',
    fechar: 'Fechar',
  },
}

export const tutorialGTM = {
  category: 'Tutorial',
  action: {
    fechar: 'Fechar',
    comecar: 'Comecar',
    fecharX: 'FecharX',
    avancar: 'Avancar',
    sair: 'Sair',
  },
}

export const recommendationBannerGTM = {
  category: 'RelatorioRecomendacoes',
  action: {
    closeModal: 'FecharModal',
    accessModal: 'AcessarModal',
  },
}

const useGTM = () => {
  const trackActionWithLabel = ({
    event, category, action, label,
  }) => {
    window.dataLayer.push({
      event,
      category,
      action,
      label,
    })
  }

  const trackActionWithNotLabel = ({ event, category, action }) => {
    window.dataLayer.push({
      event,
      category,
      action,
    })
  }

  return {
    trackActionWithLabel,
    trackActionWithNotLabel,
  }
}

export default useGTM
