import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { v4 as uuid } from 'uuid'
import { usePopper } from 'react-popper'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { noop } from 'App/utils'
import { sizes, MEDIUM } from 'App/utils/configurations'
import { isNotEmpty, isEmpty } from 'App/utils/array'
import { sameWidthModifier } from 'App/utils/popper'
import keyCodes from 'App/enums/keyCodes'
import textCommons from 'App/utils/textCommons'

import Box from 'App/components/Box'
import InputLabel from 'App/components/Input/InputLabel'
import Tooltip from 'App/components/Tooltip'

import { useFormGroupContext } from 'App/components/FormGroup/FormGroupContext'
import useOnClickOutside from 'App/hooks/useOnClickOutside'
import useOnEscape from 'App/hooks/useOnEscape'

import SelectButton from './SelectButton'
import SelectOptions from './SelectOptions'
import SelectSearch from './SelectSearch'
import SelectActions from './SelectActions'

import optionPropTypeShape from './utils/optionPropTypeShape'

import styles from './Select.module.scss'

const Select = ({
  children,
  isActionsEnabled,
  isButtonText,
  isButtonTypeCheck,
  isChecked: isCheckedProp,
  isDropdownFluid,
  isDisabled,
  isFluid,
  isGrouped,
  isLoading,
  isMultiple,
  isSearchEnabled,
  label,
  labelId,
  onCancel,
  onChange,
  onClear,
  onConfirm,
  options,
  optionText,
  optionValue,
  placeholder,
  returnObject,
  searchPlaceholder,
  size,
  tooltip,
  value,
  ...remainingProps
}) => {
  const { current: internalUuid } = useRef(uuid())
  const { status } = useFormGroupContext()
  const internalLabelId = labelId || `label-${internalUuid}`
  const dropdownId = `dropdown-${internalUuid}`
  const tooltipId = `tooltip-${internalUuid}`
  const firstSelectedOptionRef = useRef()

  const [
    buttonReference,
    setButtonReference,
  ] = useState()
  const [
    popperDropdown,
    setPopperDropdown,
  ] = useState()

  const [
    isOpen,
    setOpen,
  ] = useState(false)
  const [
    normalizedOptions,
    setNormalizedOptions,
  ] = useState([])
  const [
    filteredOptions,
    setFilteredOptions,
  ] = useState([])
  const [
    selectedOptions,
    setSelectedOptions,
  ] = useState([])

  const isOptionsControlActive = isActionsEnabled || isMultiple

  const dropdownConfig = {
    placement: 'bottom-start',
    strategy: 'fixed',
    modifiers: [!isDropdownFluid ? sameWidthModifier : {}],
  }

  const {
    styles: dropdownStyles,
    attributes: dropdownAttributes,
  } = usePopper(buttonReference, popperDropdown, dropdownConfig)

  const isChecked = (
    isButtonTypeCheck
    && isNotEmpty(selectedOptions)
    && !selectedOptions.every((selectedOption) => selectedOption.isDisabled)
  ) || isCheckedProp

  const getText = useCallback((option) => {
    if (option !== null && typeof option === 'object') {
      return option[optionText]
    }

    return option
  }, [optionText])

  const getValue = useCallback((option) => {
    if (
      option !== null
      && typeof option === 'object'
      && !returnObject
    ) {
      return option[optionValue]
    }

    return option
  }, [
    optionValue,
    returnObject,
  ])

  const handleClick = () => {
    setOpen(!isOpen)
  }

  const resetValues = useCallback(() => {
    let restoredOptions = []

    if (value && isNotEmpty(normalizedOptions)) {
      const items = isGrouped
        ? normalizedOptions
          .map((option) => option.items)
          .flatMap((item) => item)
        : normalizedOptions

      if (isMultiple) {
        restoredOptions = items
          .filter(({ $value }) => value
            .some((option) => $value === getValue(option)))
      } else {
        const selectedOption = items
          .find((option) => option.$value === getValue(value))

        if (selectedOption) {
          restoredOptions.push(selectedOption)
        }
      }
    }

    setSelectedOptions(restoredOptions)
  }, [
    value,
    normalizedOptions,
    isMultiple,
    isGrouped,
    getValue,
  ])

  const handleCancel = useCallback(() => {
    resetValues()
    setOpen(false)
    onCancel()
  }, [
    onCancel,
    resetValues,
  ])

  const handleClear = () => {
    setSelectedOptions([])
    onChange([])
    onClear()
  }

  const getCommitValue = (selected) => {
    const selectedValues = returnObject ? selected : selected.map(getValue)

    return isMultiple ? selectedValues : selectedValues[0] || null
  }

  const handleChange = (selected) => {
    setSelectedOptions(selected)

    if (!isOptionsControlActive) {
      onChange(getCommitValue(selected))
      setOpen(false)
    }
  }

  const handleConfirm = () => {
    onChange(getCommitValue(selectedOptions))
    onConfirm()
    setOpen(false)
  }

  const getButtonText = () => {
    if (isButtonTypeCheck || isEmpty(selectedOptions)) {
      return placeholder
    }

    return selectedOptions[0].$text
  }

  const getDropdownBody = () => {
    const header = isSearchEnabled && isNotEmpty(options) && (
      <SelectSearch
        isGrouped={isGrouped}
        options={normalizedOptions}
        onChange={setFilteredOptions}
      />
    )
    const body = (() => {
      if (isEmpty(options)) {
        return (
          <div className={styles.body}>
            {textCommons.errors.emptyForNow}
          </div>
        )
      }

      if (isEmpty(filteredOptions)) {
        return (
          <div className={styles.body}>
            {textCommons.list.emptyMessageWithFilter}
          </div>
        )
      }

      return (
        <SelectOptions
          firstSelectedOptionRef={firstSelectedOptionRef}
          isActionsEnabled={isActionsEnabled}
          isGrouped={isGrouped}
          isMultiple={isMultiple}
          options={filteredOptions}
          selectedOptions={selectedOptions}
          onUpdate={handleChange}
        />
      )
    })()

    return (
      <>
        {header}

        {body}
      </>
    )
  }

  const footer = isOptionsControlActive && (
    <SelectActions
      cancelText={isMultiple
        ? textCommons.actions.clear
        : textCommons.actions.cancel}
      onCancel={isMultiple ? handleClear : handleCancel}
      onConfirm={handleConfirm}
    />
  )

  useEffect(() => {
    setFilteredOptions(normalizedOptions)
  }, [normalizedOptions])

  useEffect(() => {
    function normalizeOption(option) {
      let items

      if (isGrouped) {
        items = option.items?.map(normalizeOption)
      }

      return {
        ...option,
        $text: getText(option),
        $value: getValue(option),
        items,
      }
    }

    setNormalizedOptions(options.map(normalizeOption))
  }, [
    options,
    getValue,
    getText,
    isGrouped,
  ])

  useEffect(() => {
    resetValues()
  }, [
    options,
    resetValues,
  ])

  useEffect(() => {
    const dropdownElement = popperDropdown || document
    const handleKeyDown = (event) => {
      const key = event.which || event.keyCode

      if (key === keyCodes.tab) {
        setOpen(false)
      }
    }

    dropdownElement.addEventListener('keydown', handleKeyDown)

    return () => {
      dropdownElement.removeEventListener('keydown', handleKeyDown)
    }
  }, [popperDropdown])

  useOnClickOutside(isOpen, popperDropdown, handleCancel)
  useOnEscape(isOpen, handleCancel)

  return (
    <>
      {label && (
        <InputLabel id={internalLabelId}>
          {label}
        </InputLabel>
      )}

      <span
        data-for={tooltipId}
        data-tip
      >
        <SelectButton
          ref={setButtonReference}
          aria-controls={dropdownId}
          aria-labelledby={label ? internalLabelId : null}
          counter={isButtonTypeCheck ? 0 : selectedOptions.length}
          isActive={isOpen}
          isButtonText={isButtonText}
          isChecked={isChecked}
          isDisabled={isDisabled}
          isFluid={isFluid}
          isLoading={isLoading}
          size={size}
          status={status || null}
          onClick={handleClick}
          {...remainingProps}
        >
          {getButtonText()}
        </SelectButton>
      </span>

      {tooltip && (
        <Tooltip id={tooltipId}>
          {tooltip}
        </Tooltip>
      )}

      {isOpen && !isDisabled && (
        <Box
          ref={setPopperDropdown}
          className={clsx(
            styles.dropdown,
            {
              [styles.dropdownFluid]: isDropdownFluid,
              [styles.isMultiple]: isMultiple,
            },
          )}
          elevation={3}
          id={dropdownId}
          style={dropdownStyles.popper}
          {...dropdownAttributes.popper}
        >
          {children || getDropdownBody()}

          {footer}
        </Box>
      )}
    </>
  )
}

Select.propTypes = {
  children: PropTypes.node,
  isActionsEnabled: PropTypes.bool,
  isButtonText: PropTypes.bool,
  isButtonTypeCheck: PropTypes.bool,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isDropdownFluid: PropTypes.bool,
  isFluid: PropTypes.bool,
  isGrouped: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMultiple: PropTypes.bool,
  isSearchEnabled: PropTypes.bool,
  label: PropTypes.string,
  labelId: PropTypes.string,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onConfirm: PropTypes.func,
  options:
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.shape({
        ...optionPropTypeShape,
        items: PropTypes.arrayOf(PropTypes.shape(optionPropTypeShape)),
      }),
      PropTypes.string,
      PropTypes.number,
    ])),
  optionText: PropTypes.string,
  optionValue: PropTypes.string,
  placeholder: PropTypes.string,
  // eslint-disable-next-line react/boolean-prop-naming
  returnObject: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  size: PropTypes.oneOf(sizes),
  tooltip: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ])),
  ]),
}

Select.defaultProps = {
  children: null,
  isActionsEnabled: false,
  isButtonText: false,
  isButtonTypeCheck: false,
  isChecked: false,
  isDisabled: false,
  isDropdownFluid: false,
  isFluid: false,
  isGrouped: false,
  isLoading: false,
  isMultiple: false,
  isSearchEnabled: false,
  label: null,
  labelId: null,
  onCancel: noop,
  onChange: noop,
  onClear: noop,
  onConfirm: noop,
  options: [],
  optionText: 'text',
  optionValue: 'value',
  placeholder: '',
  returnObject: false,
  searchPlaceholder: '',
  size: MEDIUM,
  tooltip: null,
  value: null,
}

export default Select
