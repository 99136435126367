const elevationsEnum = {
  noElevation: 0,
  xSmall: 1,
  small: 2,
  medium: 3,
  large: 4,
}

export const elevations = Object.values(elevationsEnum)
export default Object.freeze(elevationsEnum)
